import React from "react";
import SearchResultsTemplate from "../../../../templates/search-results-template"

const SearchResultsResiSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultsTemplate 
                locationname="/residential/property/for-sale/" 
                pcategorytype="residential" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype="For Sale" 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
            />
        </React.Fragment>
    )
}

export default SearchResultsResiSales;